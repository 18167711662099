@font-face {
  font-family: 'hattonBold';
  src: local('hattonBold'),
    url('./fonts/hatton/bold_700.otf') format('truetype');
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: 'hattonMedium';
  src: local('hattonMedium'),
    url('./fonts/hatton/medium_500.otf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'hattonLight';
  src: local('hattonLight'),
    url('./fonts/hatton/light_200.otf') format('truetype');
  font-display: swap;
}

body {
  margin: 0;
  font-family: 'DM Sans' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh !important;
  display: flex !important;
  flex-direction: column !important;
}

.fullPageWrapper {
  min-height: 100vh !important;
  margin-bottom: 30px;
}

.footer {
  margin-top: auto !important;
}

button {
  font-family: 'DM Sans' !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.w3-display-container {
  margin-top: 20px;
}

.center {
  margin: auto;
  display: block;
}

.fifty-percent {
  width: 50%;
}

.fourty-percent {
  width: 40%;
}

.col-container {
  display: table; /* Make the container element behave like a table */
}

.col {
  display: table-cell; /* Make elements inside the container behave like table cells */
}

.w3-bar {
  list-style-type: none;
}

.centeredImage {
  width: 85px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  text-align: center;
  padding: 8px 16px;
  justify-content: center;
  display: flex;
}

.sweeps-light-green-text {
  color: #006747;
}

.sweeps-light-green-bg {
  background-color: #006747;
}

.sweeps-font-bold {
  font-family: 'hattonBold';
}

.sweeps-font-medium {
  font-family: 'hattonMedium';
}

.sweeps-font-light {
  font-family: 'hattonLight';
}

.small-check {
  width: 20px;
  height: 20px;
}

.w3-teal {
  background-color: #006747 !important;
}

.w3-text-teal {
  color: #006747 !important;
}

.pointer:hover {
  cursor: pointer;
}

.match-border {
  border-radius: 20px !important;
  border-width: 2px !important;
  border-color: #006747 !important;
  border-style: solid !important;
}

@media screen and (max-width: 600px) {
  .col-container {
    display: block !important;
  }
}

.col-container {
  display: flex; /* Make the container element behave like a table */
  width: 100%; /* Set full-width to expand the whole page */
}

.times {
  font-size: 30px;
}

.col-mine {
  flex: 1; /* Make elements inside the container behave like table cells */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 10px !important;
}

.PhoneInputCountrySelectArrow {
  width: 9px;
  height: 9px;
  border-color: black !important;
}

@media screen and (min-width: 600px) {
  .bottomButton {
    position: absolute;
    bottom: 0px;
  }
}

.rdt_ExpanderRow {
  width: 40px;
}

.mySlides {
  display: none;
}
.w3-left,
.w3-right,
.w3-badge {
  cursor: pointer;
}
.w3-badge {
  height: 13px;
  width: 13px;
  padding: 0;
}

.w3-card {
  border-radius: 20px;
  overflow: hidden;
}

.w3-modal-content {
  border-radius: 20px;
  overflow: hidden;
}

.w3-btn {
  border-radius: 5px;
}

.w3-button {
  border-radius: 5px;
}

.w3-text-spink {
  color: #9d8189;
}

.w3-text-sviolet {
  color: #5b618a;
}

.w3-sviolet {
  background-color: #5b618a;
}

a {
  color: #5b618a !important;
}

@media screen and (max-width: 500px) {
  .w3-margin {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }

  .w3-margin-left {
    margin-left: 4px !important;
  }

  .w3-row-padding {
    padding: 4px !important;
  }
}

.btn-margin-left {
  margin-left: 8px;
}

.no-margin-top {
  margin-top: 0px !important;
}

.no-margin-bottom {
  margin-bottom: 0px !important;
}

.bold {
  font-weight: 800;
}

.w3-padding-right {
  padding-right: 10px;
}

.MuiAppBar-root {
  background-color: #006747 !important;
}

.hover-white:hover {
  background-color: #5b618a !important;
}

.center {
  text-align: center !important;
}

.Mui-disabled > span {
  color: grey !important;
  border: 10px solid grey !important;
}

.MuiSlider-root:not(.Mui-disabled) > span {
  color: #006747 !important;
  border: 10px solid #006747 !important;
}

.MuiSlider-thumb {
  color: #006747;
}
.MuiSlider-rail {
  color: #5b618a;
}
.MuiSlider-track {
  color: #006747;
  border-radius: 0px !important;
  border: 10px solid #006747 !important;
}
